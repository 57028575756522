// Englobe les iframe de vidéos youtube pour gérer le ratio
function wrapYtVideo() {
  let i;
  let youtubeVideosOuterHtml;
  let newYoutubeVideosOuterHtml;
  const youtubeVideos = document.querySelectorAll('.dynamic iframe[src*="youtube.com"],.dynamic iframe[src*="vimeo.com"]');
  const youtubeVideosLength = youtubeVideos.length;

  for (i = 0; i < youtubeVideosLength; i += 1) {
    youtubeVideosOuterHtml = youtubeVideos[i].outerHTML;
    newYoutubeVideosOuterHtml = `<div class='videoWrapper'><div class='ytVideo'>${youtubeVideosOuterHtml}</div></div>`;
    youtubeVideos[i].outerHTML = newYoutubeVideosOuterHtml;
  }
}

// Ajouter des marges pour les <p> qui contiennent des boutons
function adjustButtonsMargins() {
  let i;
  const buttons = document.querySelectorAll('.dynamic p > a.primary-button, .dynamic p > a.secondary-button');
  const buttonsLength = buttons.length;

  for (i = 0; i < buttonsLength; i += 1) { buttons[i].parentNode.classList.add('buttons'); }

  const buttonsParagraph = document.querySelectorAll('.dynamic p.buttons');
  const buttonsParagraphLength = buttonsParagraph.length;

  for (i = 0; i < buttonsParagraphLength; i += 1) {
    if (buttonsParagraph[i].previousElementSibling !== null) {
      if (!buttonsParagraph[i].previousElementSibling.classList.contains('buttons')) {
        buttonsParagraph[i].classList.add('buttons--first');
      }
    }
    if (buttonsParagraph[i].nextElementSibling !== null) {
      if (!buttonsParagraph[i].nextElementSibling.classList.contains('buttons')) {
        buttonsParagraph[i].classList.add('buttons--last');
      }
    }
  }
}

// Ajouter un <span> dans les boutons pour l'animation
function addSpanToButtons() {
  let i;
  let buttonsInnerHtml;
  let newButtonsInnerHtml;
  const buttons = document.querySelectorAll('.dynamic .primary-button, .dynamic .secondary-button');
  const buttonsLength = buttons.length;

  for (i = 0; i < buttonsLength; i += 1) {
    buttonsInnerHtml = buttons[i].innerHTML;
    newButtonsInnerHtml = `<span>${buttonsInnerHtml}</span>`;
    buttons[i].innerHTML = newButtonsInnerHtml;
  }
}

// Ajouter un <span> dans les liens pour le souligné
function addSpanToLinks() {
  let i;
  let linksInnerHtml;
  let newLinksInnerHtml;
  const links = document.querySelectorAll('.dynamic a:not(.primary-button):not(.secondary-button)');
  const linksLength = links.length;

  for (i = 0; i < linksLength; i += 1) {
    linksInnerHtml = links[i].innerHTML;
    newLinksInnerHtml = `<span>${linksInnerHtml}</span>`;
    links[i].innerHTML = newLinksInnerHtml;
  }
}

// Ajouter les icones svg pour les boutons et les liens textes
function addSvgToLinks() {
  const themePath = window.config.theme_path;
  const sltr = '.dynamic a[target=_blank]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])';
  const linksExternal = document.querySelectorAll(sltr);

  linksExternal.forEach((link) => {
    if (!link.querySelector('img')) {
      const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');
      useElem.setAttributeNS(
        'http://www.w3.org/1999/xlink',
        'xlink:href',
        `/themes/${themePath}/assets/medias/images/icons/symbols.svg#ico-external`,
      );
      svgElem.appendChild(useElem);
      if (!link.classList.contains('primary-button') && !link.classList.contains('secondary-button')) {
        link.appendChild(document.createTextNode('\xa0'));
      }
      link.appendChild(svgElem);
      if (!link.classList.contains('primary-button') && !link.classList.contains('secondary-button')) {
        link.appendChild(document.createTextNode('\xa0\xa0'));
      }
    }
  });

  const sltr2 = '.dynamic a[href$=".pdf"], .dynamic a[href$=".doc"], .dynamic a[href$=".zip"]';
  const linksDocuments = document.querySelectorAll(sltr2);
  linksDocuments.forEach((link) => {
    if (!link.querySelector('img')) {
      const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');
      useElem.setAttributeNS(
        'http://www.w3.org/1999/xlink',
        'xlink:href',
        `/themes/${themePath}/assets/medias/images/icons/symbols.svg#ico-download`,
      );
      svgElem.appendChild(useElem);
      if (!link.classList.contains('primary-button') && !link.classList.contains('secondary-button')) {
        link.appendChild(document.createTextNode('\xa0'));
      }
      link.appendChild(svgElem);
      if (!link.classList.contains('primary-button') && !link.classList.contains('secondary-button')) {
        link.appendChild(document.createTextNode('\xa0\xa0'));
      }
      link.setAttribute('target', '_blank');
    }
  });
}

// Ajouter des balises dans les liens de type documents pour que ça ressemble aux documents sous contenu
function addMarkupToLinksDocuments() {
  const themePath = window.config.theme_path;
  const linksDocuments = document.querySelectorAll('.dynamic a.docs');
  const linksDocuementLength = linksDocuments.length;

  for (let i = 0; i < linksDocuementLength; i += 1) {
    const linkDocumentHref = linksDocuments[i].href;
    const linkDocumentTextContent = linksDocuments[i].textContent;

    linksDocuments[i].setAttribute('class', 'small-document');
    linksDocuments[i].setAttribute('href', linkDocumentHref);
    linksDocuments[i].setAttribute('target', '_blank');
    // eslint-disable-next-line max-len
    linksDocuments[i].innerHTML = `<div class="small-document__svg-container"><svg class="small-document__svg" role="img"><use xlink:href="/themes/${themePath}/assets/medias/images/icons/symbols.svg#ico-download"></use></svg></div><div class="small-document__content"><span class="small-document__title">${linkDocumentTextContent}</span></div>`;
  }
}

// Ajouter les icones svg pour les boutons et les liens textes
function manageImageLinks() {
  const links = document.querySelectorAll('.dynamic a');
  links.forEach((link) => {
    if (link.querySelector('img')) {
      link.classList.add('link-image');
    }
  });
}

// Mettre des target _blank automatiquement sur les liens externes
function autoTargetBlank() {
  document.querySelectorAll('.dynamic a').forEach((link) => {
    if (link.hostname !== window.location.hostname) {
      link.setAttribute('target', '_blank');
    }
  });
}

// Le package à appliquer pour les zones dynamiques
export default function dynamicPackage() {
  wrapYtVideo();
  adjustButtonsMargins();
  autoTargetBlank();
  addSpanToButtons();
  addSpanToLinks();
  addSvgToLinks();
  addMarkupToLinksDocuments();
  manageImageLinks();
}
