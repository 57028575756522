import $ from 'jquery';
import ShareButtons from 'share-buttons/dist/share-buttons';
import { OBSERVER } from '../plugins';
import Overlay from '../vendor/overlay';
import { calendar } from './functions';

// Fonction permettant de donner un attribut height au container de l'overlay
export function addHeightAttributeToContainer(classOverlay, nameEventOverlay, targetContainerOverlay) {
  const containerOverlay = document.querySelector(targetContainerOverlay);

  function onOpen() {
    containerOverlay.style.height = `${containerOverlay.clientHeight}px`;
  }

  function onCloseComplete() {
    containerOverlay.style.height = null;
  }

  function onResize() {
    containerOverlay.style.height = null;
    containerOverlay.style.height = `${containerOverlay.clientHeight}px`;
  }

  OBSERVER.add({
    name: `overlay${nameEventOverlay}`,
    events: `onOpenOverlay${nameEventOverlay}`,
    function: onOpen,
  });
  OBSERVER.add({
    name: `overlay${nameEventOverlay}`,
    events: `onCloseCompleteOverlay${nameEventOverlay}`,
    function: onCloseComplete,
  });
  OBSERVER.add({
    name: `overlay${nameEventOverlay}`,
    events: 'resize',
    function: onResize,
  });
  OBSERVER.on(`overlay${nameEventOverlay}`);
}

// Fonction permettant de gérer la visibilité des sections de l'overlay
export function manageVisibilitySections(classOverlay, nameEventOverlay, classSectionOverlay, classSectionOverlayActive) {
  let lastNameSectionActive;

  function showSection(section) {
    section.classList.add(classSectionOverlayActive);
  }

  function hideSection(section) {
    section.classList.remove(classSectionOverlayActive);
  }

  function onTrigger() {
    const nameSectionActive = document.querySelector('html').dataset.section;
    const sectionActive = document.querySelector(`[data-overlay="${classOverlay.overlay.name}"] .${classSectionOverlay}[data-section="${nameSectionActive}"]`);
    const lastSectionActive = document.querySelector(`[data-overlay="${classOverlay.overlay.name}"] .${classSectionOverlay}[data-section="${lastNameSectionActive}"]`);

    // Overlay ouvert avec une section active
    if (classOverlay.getStatus() !== 'close') {
      hideSection(lastSectionActive);
    }

    showSection(sectionActive);
    lastNameSectionActive = nameSectionActive;
  }

  function onClose() {
    const lastSectionActive = document.querySelector(`[data-overlay="${classOverlay.overlay.name}"] .${classSectionOverlay}[data-section="${lastNameSectionActive}"]`);

    // Overlay qui ferme
    hideSection(lastSectionActive);
    lastNameSectionActive = null;
  }

  OBSERVER.add({
    name: `overlay${nameEventOverlay}`,
    events: `onTriggerOverlay${nameEventOverlay}`,
    function: onTrigger,
  });
  OBSERVER.add({
    name: `overlay${nameEventOverlay}`,
    events: `onCloseOverlay${nameEventOverlay}`,
    function: onClose,
  });
  OBSERVER.on(`overlay${nameEventOverlay}`);
}

// Fonction gérant l'overlay du partage
export function overlayShare() {
  if (document.querySelector('[data-overlay="share"]')) {
    const ovShare = new Overlay({
      name: 'share',
      events: {
        open: true,
        closeComplete: true,
      },
      click: {
        buttons: {
          open: '.js-open-overlay-share',
          close: '.js-close-overlay-share',
        },
      },
      options: {
        speed: 800,
      },
    });

    ovShare.init();

    // Fonction permettant de donner un attribut height au container de l'overlay (mettre events open et closeComplete à true)
    addHeightAttributeToContainer(ovShare, 'Share', '[data-overlay-container="share"]');

    // Message de confirmation de copie de l'URL ----------------
    const confirmClipboardCopy = () => {
      setTimeout(() => {
        // eslint-disable-next-line no-alert
        alert('L’adresse URL a bien été copiée dans votre presse-papier.');
      }, 300);
    };

    OBSERVER.add({
      name: 'confirmClipboardCopy',
      events: 'click',
      targets: '.js-share-copy',
      function: confirmClipboardCopy,
    });
    OBSERVER.on('confirmClipboardCopy');

    // Gestion de l'ouverture de l'overlay ----------------------
    const openShareOverlay = (e) => {
      if (e.currentTarget.dataset.rubric) {
        window.history.pushState(null, null, `#${e.currentTarget.dataset.rubric}`);
      }
      document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
      ShareButtons.update();
    };

    OBSERVER.add({
      name: 'openShareOverlay',
      events: 'click',
      targets: '.js-open-overlay-share',
      function: openShareOverlay,
    });
    OBSERVER.on('openShareOverlay');

    // Gestion de la fermeture de l'overlay ---------------------
    const closeShareOverlay = () => {
      window.history.replaceState(null, null, ' ');
      document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
      ShareButtons.update();
    };

    OBSERVER.add({
      name: 'closeShareOverlay',
      events: 'click',
      targets: '.js-close-overlay-share',
      function: closeShareOverlay,
    });
    OBSERVER.on('closeShareOverlay');
  }
}

// Fonction gérant l'overlay du calendrier
export function overlayCalendar() {
  if (document.querySelector('[data-overlay="calendar"]')) {
    const ovCalendar = new Overlay({
      name: 'calendar',
      click: {
        buttons: {
          open: '.js-open-overlay-calendar',
          close: '.js-close-overlay-calendar',
        },
      },
      options: {
        speed: 800,
      },
    });

    ovCalendar.init();
    calendar();
  }
}

// Fonction gérant l'overlay de recherche
export function overlaySearch() {
  if (document.querySelector('[data-overlay="search"]')) {
    const ovSearch = new Overlay({
      name: 'search',
      events: {
        openComplete: true,
        closeComplete: true,
      },
      click: {
        buttons: {
          trigger: '.js-trigger-overlay-search',
          close: '.js-close-overlay-search',
        },
      },
      options: {
        speed: 800,
        closeOnResize: false,
      },
    });

    ovSearch.init();

    const onOpenComplete = () => {
      const inputSearch = document.querySelector('.js-overlay-search-search-field-input');
      inputSearch.focus();
    };

    const onCloseComplete = () => {
      const inputSearch = document.querySelector('.js-overlay-search-search-field-input');
      inputSearch.value = '';
    };

    let ajaxRequest = false;
    const changeSection = (e) => {
      const inputSearch = e;
      const html = document.querySelector('html');

      if (ajaxRequest) {
        // Si une requête est déjà en cours, l'annuler pour faire place à la prochaine
        ajaxRequest.abort();
        if (html.dataset.section !== 'suggestions') {
          ovSearch.trigger('suggestions');
        }
      }

      if (inputSearch.target.value.length >= 3) {
        ajaxRequest = $.request('rechercheAutocomplete::onFetchAutocomplete', {
          data: { q: e.currentTarget.value },
          update: { 'rechercheAutocomplete::autocomplete': '#overlay-search #autocomplete' },
          complete(data) {
            // Results loaded.
            ajaxRequest = false;

            // On affiche l'overlay des suggestions juste si ya des résultats
            if (data.responseJSON && data.responseJSON['rechercheAutocomplete::autocomplete']) {
              if (html.dataset.section !== 'fast-results') {
                ovSearch.trigger('fast-results');
              }
            } else {
              // eslint-disable-next-line no-lonely-if
              if (html.dataset.section !== 'suggestions') {
                ovSearch.trigger('suggestions');
              }
            }
          },
        });
      } else if (inputSearch.target.value.length < 3 && html.dataset.section !== 'suggestions') {
        ovSearch.trigger('suggestions');
      }
    };

    OBSERVER.add({
      name: 'overlaySearch',
      events: 'onOpenCompleteOverlaySearch',
      function: onOpenComplete,
    });
    OBSERVER.add({
      name: 'overlaySearch',
      events: 'onCloseCompleteOverlaySearch',
      function: onCloseComplete,
    });
    OBSERVER.add({
      name: 'overlaySearch',
      events: 'input',
      targets: '.js-overlay-search-search-field-input',
      function: changeSection,
    });
    OBSERVER.on('overlaySearch');
  }
}

// Fonction gérant l'overlay des liens rapides
export function overlayFastLinks() {
  if (document.querySelector('[data-overlay="fast-links"]')) {
    const ovFastLinks = new Overlay({
      name: 'fast-links',
      events: {
        open: true,
        close: true,
      },
      click: {
        buttons: {
          open: '.js-open-overlay-fast-links',
          close: '.js-close-overlay-fast-links',
        },
      },
      options: {
        speed: 800,
        closeOnResize: true,
      },
    });

    ovFastLinks.init();
  }
}

// Fonction gérant l'overlay du menu mobile
export function overlayMenuMobile() {
  if (document.querySelector('[data-overlay="menu-mobile"]')) {
    const ovMenuMobile = new Overlay({
      name: 'menu-mobile',
      events: {
        close: true,
        trigger: true,
      },
      click: {
        buttons: {
          trigger: '.js-trigger-overlay-menu-mobile',
          close: '.js-close-overlay-menu-mobile',
          switch: '.js-trigger-overlay-search',
        },
      },
      options: {
        speed: 800,
        closeOnResize: true,
      },
    });

    ovMenuMobile.init();

    // Fonction permettant de gérer la visibilité des sections de l'overlay (mettre events trigger et close à true)
    manageVisibilitySections(ovMenuMobile, 'Menu-mobile', 'js-overlay-section', 'js-overlay-section-active');
  }
}

export function overlayDropdown() {
  if (document.querySelector('[data-overlay="dropdown"]')) {
    const ovDropdown = new Overlay({
      name: 'dropdown',
      click: {
        buttons: {
          trigger: '.js-trigger-overlay-dropdown',
          close: '.js-close-overlay-dropdown, a[href]',
          switch: '.js-open-overlay-fast-links, .js-trigger-overlay-search',
        },
      },
      options: {
        speed: 1000,
        closeOnResize: true,
      },
      events: {
        close: true,
        trigger: true,
      },
    });

    ovDropdown.init();
  }
}

// Fonction gérant l'overlay du popup
export function overlayPopup() {
  if (document.querySelector('[data-overlay="popup"]')) {
    const ovPopup = new Overlay({
      name: 'popup',
      events: {
        open: true,
        closeComplete: true,
      },
      click: {
        buttons: {
          close: '.js-close-overlay-popup',
        },
      },
      timeout: {
        delay: 2000,
      },
      options: {
        speed: 800,
      },
    });

    ovPopup.init();

    // Fonction permettant de donner un attribut height au container de l'overlay (mettre events open et closeComplete à true)
    addHeightAttributeToContainer(ovPopup, 'Popup', '[data-overlay-container="popup"]');
  }
}
