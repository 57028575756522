import barba from '@barba/core';
import LazyLoad from 'vanilla-lazyload';
import ShareButtons from 'share-buttons/dist/share-buttons';
import { SCROLLFIRE, OBSERVER } from './plugins';
import fadeTransition from './transitions/fade-transition';
import {
  calendar,
  backendKeyBind,
  ogContent,
  animationWaveButton,
  zoomFontSize,
  tooltipPictureCredits,
  changeSelect,
  showHideStickyBottomPageHome,
  changeInputPlaceholder,
} from './functions/functions';
import { rubrics, rubricsShowHideMoreItems } from './functions/rubrics';
import dynamicPackage from './functions/dynamic';
import {
  resetDropzone, formsPackage, tailSelect, clearInput, inputsAndTextareaLabel,
} from './functions/form';
import {
  overlayShare, overlayCalendar, overlaySearch, overlayFastLinks, overlayMenuMobile, overlayDropdown, overlayPopup,
} from './functions/overlays';
import Overlay from './vendor/overlay';
import {
  formSuccess, formNewsletter, formContact, formJobs,
} from './functions/validation';
import masksPackage from './functions/masks';
import { fontSizeBasedOnCaracters, removeHoverOnMobile, hundredVH } from './functions/helper';
import { clickToScrollToBlock } from './functions/scrollToBlock';
import { swiperFeaturedEvents, swiperAlert, swiperHomeBanner } from './functions/sliders';
import separateSentenceIntoWords from './functions/separate-sentence-into-words';
import {
  googleMap, destroyMap, overlayLocation, overlayLocations, subMenu, drawers, goBackMap,
} from './functions/map/map';
import faqFilters from './functions/faq';

window.formSuccess = formSuccess;
window.resetDropzone = resetDropzone;

const initBarba = () => {
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null) {
      current.container.remove();
    }
    overlaySearch();
    overlayFastLinks();
    overlayMenuMobile();
    overlayShare();
    overlayDropdown();
    overlayPopup();
    swiperAlert();
    rubricsShowHideMoreItems();
    tooltipPictureCredits();
    animationWaveButton();
    fontSizeBasedOnCaracters();
    zoomFontSize();
    hundredVH();
    clickToScrollToBlock({ selector: '.js-footer-button-back-to-top', scrollTo: 'body' });
    backendKeyBind();
    ShareButtons.update();
    removeHoverOnMobile();
    dynamicPackage();
  });

  barba.hooks.enter((data) => {
    ogContent(data);
  });

  barba.hooks.afterLeave(() => {
    OBSERVER.allOff();
    OBSERVER.removeAll();
    Overlay.destroyAll();
    SCROLLFIRE.destroy();
  });

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [fadeTransition()],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          // composante FAQ
          tailSelect();
          changeSelect('#faqFilterSelect');

          rubrics();
          masksPackage();
          calendar();
          clickToScrollToBlock({ selector: '.js-scroll-to-first-section-content', scrollTo: '.js-first-section-content' });
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
          initFormBuilder(); // eslint-disable-line

          if (document.querySelector('#form-example')) {
            formsPackage();
          }
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          swiperHomeBanner();
          swiperFeaturedEvents();
          separateSentenceIntoWords();
          inputsAndTextareaLabel();
          clearInput();
          showHideStickyBottomPageHome();
          changeInputPlaceholder();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'eventView',
        beforeEnter() {
          clickToScrollToBlock({ selector: '.js-scroll-to-first-section-content', scrollTo: '.js-first-section-content' });
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'eventsView',
        beforeEnter() {
          tailSelect();
          changeSelect('#events-sort');
          overlayCalendar();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'publicationView',
        beforeEnter() {
          clickToScrollToBlock({ selector: '.js-scroll-to-first-section-content', scrollTo: '.js-first-section-content' });
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'publicationsView',
        beforeEnter() {
          tailSelect();
          changeSelect('#publications-sort');
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsletterView',
        beforeEnter() {
          formNewsletter();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          formContact();
          rubrics();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'faqsView',
        beforeEnter() {
          inputsAndTextareaLabel();
          clearInput();
          faqFilters();
          rubrics();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'searchView',
        beforeEnter() {
          inputsAndTextareaLabel();
          clearInput();
          tailSelect();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'sectionView',
        beforeEnter() {
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'jobsView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          formJobs();
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'jobView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          formJobs();
          clickToScrollToBlock({ selector: '.js-scroll-to-first-section-content', scrollTo: '.js-first-section-content' });
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'mapView',
        beforeEnter() {
          overlayLocation();
          overlayLocations();
          goBackMap();
          subMenu();
          drawers();
          googleMap();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          destroyMap();
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'maintenanceView',
        beforeEnter() {
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'errorView',
        beforeEnter() {
          SCROLLFIRE.init();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
    ],
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initBarba();
});
