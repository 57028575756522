import Accordions from '../classes/accordions';

// Permet d'initialiser les rubrics avec un offset dynamique ou sans de offset
// Pour un offset dynamique, on doit ajouter des fonctions (ex: onResize, onCloseAlert) pour changer le offset
export function rubrics() {
  const rubric = new Accordions();
  rubric.offset = 0;
  rubric.init();
}

// Permet d'initialiser les rubrics de filtres
export function rubricsShowHideMoreItems() {
  const rubricShowHideMoreItems = new Accordions({
    containerClass: 'js-accordions-show-hide-more-items',
    accordionClass: 'js-accordion-show-hide-more-item',
    toggleButtonClass: 'js-accordion-show-hide-more-item-toggle',
    contentClass: 'js-accordion-show-hide-more-item-content',
    openedClass: 'js-accordion-show-hide-more-item-opened',
    closeAllBeforeOpen: false,
    scrollToAccordion: false,
  });
  rubricShowHideMoreItems.offset = 0;
  rubricShowHideMoreItems.init();
}
