import { OBSERVER } from '../plugins';

// Retourne si le site est ouvert dans un appareil mobile
export function isMobile() {
  let condition = false;

  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    condition = true;
  }

  return condition;
}

// Retourne le offset d'un élément dans la page
export function getElementOffset(options) {
  let box;

  if (typeof options.element === 'string') {
    box = document.querySelector(options.element).getBoundingClientRect();
  } else {
    box = options.element.getBoundingClientRect();
  }

  const { body } = document;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
}

// Retourne le poids d'un fichier
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 ko';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Octets', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

// Retourne si un élément est visible dans le viewport
export function isVisible(el) {
  const rect = el.getBoundingClientRect();

  const bottom = rect.bottom >= 0;
  const right = rect.right >= 0;
  const top = rect.top <= (window.innerHeight || document.documentElement.clientHeight);
  const left = rect.left <= (window.innerWidth || document.documentElement.clientWidth);

  return bottom && right && top && left;
}

// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try {
      // prevent exception on browsers not supporting DOM styleSheets properly
      let k;
      const { styleSheets } = document;
      const styleSheetsLength = styleSheets.length;
      for (k = 0; k < styleSheetsLength; k += 1) {
        // for (const si in document.styleSheets) {
        const styleSheet = styleSheets[k];
        if (styleSheet.rules) {
          for (let ri = styleSheet.rules.length - 1; ri >= 0; ri -= 1) {
            if (styleSheet.rules[ri].selectorText) {
              if (styleSheet.rules[ri].selectorText.match(':hover')) {
                const { selectorText } = styleSheet.rules[ri];
                const araySelectorText = selectorText.split(',');
                let newSelectorText = '';

                // Garder slm les selectorText no :hover
                for (let i = 0; i < araySelectorText.length; i += 1) {
                  if (!araySelectorText[i].match(':hover')) {
                    newSelectorText += newSelectorText !== '' ? `, ${araySelectorText[i]}` : ` ${araySelectorText[i]}`;
                  }
                }

                // Enlver la règle si la seule classe est un :hover
                if (newSelectorText === '') {
                  styleSheet.deleteRule(ri);
                } else {
                  styleSheet.rules[ri].selectorText = newSelectorText;
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
}

// Réduit la taille des H1 s'il comporte trop de caractères
// data-font-size-based-on-caracters-count="{font-size}:min-max,{font-size}:min-max"
export function fontSizeBasedOnCaracters() {
  const elements = document.querySelectorAll('[data-font-size-based-on-caracters-count]');
  const elementsLength = elements.length;

  let i;
  let j;

  for (i = 0; i < elementsLength; i += 1) {
    const element = elements[i];
    const elementTextLength = element.textContent.length;
    const fontSizesDataAttributeValue = element.getAttribute('data-font-size-based-on-caracters-count');
    const fontSizes = fontSizesDataAttributeValue.split(',');
    const fontSizesLength = fontSizes.length;

    for (j = 0; j < fontSizesLength; j += 1) {
      const fontSizeAndCount = fontSizes[j];
      const fontSizeAndCountArray = fontSizeAndCount.split(':');

      const fontSize = fontSizeAndCountArray[0];

      const countMinAndMax = fontSizeAndCountArray[1];
      const countMinAndMaxArray = countMinAndMax.split('-');
      const min = countMinAndMaxArray[0];
      const max = countMinAndMaxArray[1];

      if (elementTextLength >= min && elementTextLength <= max) {
        element.classList.add(`u-font-size-${fontSize}`);
      }
    }
  }
}

// Permet de donner la hauteur exact en mobile de 100vh
// Css -> height: calc(var(--vh, 1vh) * 100);
export function hundredVH() {
  function setHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  OBSERVER.add({
    name: 'hundredVH',
    events: 'resize load',
    targets: 'window',
    function: setHeight,
  });
  OBSERVER.on('hundredVH');
}

// Permet de diviser un mot en mot en charactères
export function splitWordIntoCharacters(target) {
  const elements = document.querySelectorAll(target);
  let characterIndex = 0;

  for (let i = 0; i < elements.length; i += 1) {
    if (!elements[i].querySelectorAll('.character').length) {
      const element = elements[i];
      const characters = element.textContent.split('');
      let word = '';

      for (let j = 0; j < characters.length; j += 1) {
        const character = characters[j];
        characterIndex += 1;
        word += `<span class="character" data-character="${character}" style="--character-index: ${characterIndex};">${character}</span>`;
      }

      element.innerHTML = word;
    }
  }
}

// Permet de diviser une phrase en mots
export function splitSentenceIntoWords(target, doSplitWordIntoCharacters = false) {
  const elements = document.querySelectorAll(target);

  for (let i = 0; i < elements.length; i += 1) {
    const element = elements[i];
    let string = element.innerHTML;

    // Remplace les balises <br> par un espace ' ' pour éviter des problèmes
    if (string.includes('<br>')) {
      string = string.replace('<br>', ' ');
    }
    if (string.includes('<br/>')) {
      string = string.replace('<br/>', ' ');
    }
    if (string.includes('<br />')) {
      string = string.replace('<br />', ' ');
    }

    const words = string.split(' ');
    let sentence = '';

    for (let j = 0; j < words.length; j += 1) {
      const word = words[j];

      sentence += `<span class="word" data-word="${word}" style="--word-index: ${j};">${word}</span>`;

      if (j + 1 < words.length) {
        sentence += '<span class="whiteSpace">&nbsp;</span>';
      }
    }

    element.innerHTML = sentence;

    if (doSplitWordIntoCharacters) {
      splitWordIntoCharacters('.word');
    }
  }
}

// Permet de diviser une phrase en mots et un mot en charactères
export function splitSentenceIntoWordsAndIntoCharacters(target, doSplitWordIntoCharacters) {
  splitSentenceIntoWords(target, doSplitWordIntoCharacters);
}

// Configurer un cookie
export function setCookie(name, value, exdays = '') {
  const date = new Date();
  date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name} = ${value}; ${expires};path=/`;
}

// Recevoir un cookie
export function getCookie(cookie) {
  const name = `${cookie}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  let i;
  for (i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
