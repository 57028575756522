import $ from 'jquery';
import { OBSERVER } from '../plugins';

function genCharArray(charA, charZ) {
  const a = [];
  let i = charA.charCodeAt(0);
  const j = charZ.charCodeAt(0);
  for (; i <= j; i += 1) {
    a.push(String.fromCharCode(i));
  }
  return a;
}

function onCLickLetter(e) {
  const alphabet = genCharArray('A', 'Z');
  if (!($(e.target).hasClass('seeAll'))) {
    const letter = e.target.id.replace('letter_', '');
    $('#letterIndex a').removeClass('letters-filter__letter--active');
    $(e.target).addClass('letters-filter__letter--active');
    for (let i = 0; i < alphabet.length; i += 1) {
      if (letter !== alphabet[i]) {
        $(`#wrapper_${alphabet[i]}`).animate({ height: 'hide' }, { duration: 800, specialEasing: { height: 'easeInOutCubic' } });
        $(`#wrapper_${alphabet[i]}`).removeClass('layout__faq-links--active');
      } else {
        $(`#wrapper_${alphabet[i]}`).animate({ height: 'show' }, { duration: 800, specialEasing: { height: 'easeInOutCubic' } });
        $(`#wrapper_${alphabet[i]}`).addClass('layout__faq-links--active');
      }
    }
  }
}

function onClickSeeAll() {
  const alphabet = genCharArray('A', 'Z');
  $('#letterIndex a').removeClass('letters-filter__letter--active');
  $('.seeAll').addClass('letters-filter__letter--active');
  for (let i = 0; i < alphabet.length; i += 1) {
    $(`#wrapper_${alphabet[i]}`).animate({ height: 'show' }, { duration: 800, specialEasing: { height: 'easeInOutCubic' } });
    $(`#wrapper_${alphabet[i]}`).addClass('layout__faq-links--active');
  }
}

export default function faqFilters() {
  OBSERVER.add({
    name: 'faqFilters',
    events: 'click',
    targets: '#letterIndex a',
    function: onCLickLetter,
  });
  OBSERVER.on('faqFilters');

  OBSERVER.add({
    name: 'faqFilters',
    events: 'click',
    targets: '.seeAll',
    function: onClickSeeAll,
  });
  OBSERVER.on('faqFilters');
}
