// Documentation: https://swiperjs.com/swiper-api
// Exemple de slider dans dtg-dentistes
import Swiper from 'swiper/bundle';
import { OBSERVER } from '../plugins';
import { isVisible } from './helper';
import { tooltipPictureCredits } from './functions';

// Swiper des événements en vedettes
export function swiperFeaturedEvents() {
  if (document.querySelector('[data-swiper="featured-events"]')) {
    window.swiperEvents = new Swiper('[data-swiper="featured-events"]', {
      slidesPerView: 'auto',
      loop: (document.querySelectorAll('[data-swiper="featured-events"] .swiper-slide').length > 1),
      spaceBetween: 35,
      threshold: 10,
      speed: 700,
      mousewheel: {
        forceToAxis: true,
        sensitivity: 0.5,
      },
      navigation: {
        prevEl: '[data-swiper-prev="featured-events"]',
        nextEl: '[data-swiper-next="featured-events"]',
      },
      breakpoints: {
        700: {
          spaceBetween: 40,
        },
        900: {
          spaceBetween: 50,
        },
        1200: {
          spaceBetween: 57,
        },
      },
    });
  }
}

export function swiperHomeBanner() {
  if (document.querySelector('[data-swiper="home-banner"]')) {
    window.homeBanner = new Swiper('[data-swiper="home-banner"]', {
      slidesPerView: 'auto',
      loop: (document.querySelectorAll('[data-swiper="home-banner"] .swiper-slide').length > 1),
      threshold: 10,
      speed: 700,
      autoHeight: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoplay: true,
      pagination: {
        el: '[data-swiper="home-banner-pagination-bullets"]',
        type: 'bullets',
        clickable: true,
      },
      on: {
        slideChange() {
          tooltipPictureCredits();
        },
      },
    });

    // Mettre en marche/arrêt l'autoplay selon si le swiper est dans le viewport
    const onScroll = () => {
      const layoutHomeBanner = document.querySelector('.js-layout-home-banner');

      if (isVisible(layoutHomeBanner) && !window.homeBanner.autoplay.running) {
        window.homeBanner.autoplay.start();
      } else if (!isVisible(layoutHomeBanner) && window.homeBanner.autoplay.running) {
        window.homeBanner.autoplay.stop();
      }
    };

    // Si la bannière est une vidéo, alors on lance la vidéo
    const video = document.querySelector('.js-home-banner-video');
    if (video && window.innerWidth >= 900) {
      video.play();
    }

    OBSERVER.add({
      name: 'swiperHomeBanner',
      events: 'scroll load',
      targets: 'window',
      function: onScroll,
    });
    OBSERVER.on('swiperHomeBanner');
  }
}

export function swiperAlert() {
  const onClose = new CustomEvent('onCloseAlerts');

  if (document.querySelector('.js-alerts')) {
    document.documentElement.style.setProperty('--alerts-height', '40px');
  }

  const closeAlerts = () => {
    dispatchEvent(onClose);
    document.documentElement.style.setProperty('--alerts-height', '0px');
  };

  OBSERVER.add({
    name: 'alerts',
    events: 'click',
    function: closeAlerts,
    targets: '[data-swiper="swiper-alerts-close"]',
  });
  OBSERVER.on('alerts');

  const sliderName = 'alerts';
  window.quotesSlider = new Swiper(`[data-swiper="${sliderName}"]`, {
    speed: 700,
    threshold: 10,
    slidesPerView: 'auto',
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: '[data-swiper="swiper-alerts-pagination"]',
      type: 'fraction',
    },
    navigation: {
      prevEl: '[data-swiper="swiper-alerts-prev"]',
      nextEl: '[data-swiper="swiper-alerts-next"]',
    },
    runCallbacksOnInit: true,
    on: {
      init(swm) {
        const fraction = document.querySelector('[data-swiper="swiper-alerts-pagination-fraction"]');
        fraction.innerHTML = `${swm.realIndex + 1}/${swm.slides.length - (swm.loopedSlides * 2)}`;
      },
      slideChange(swm) {
        const fraction = document.querySelector('[data-swiper="swiper-alerts-pagination-fraction"]');
        fraction.innerHTML = `${swm.realIndex + 1}/${swm.slides.length - (swm.loopedSlides * 2)}`;
      },
    },
  });
}
